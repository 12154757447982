.filter {
  position: relative;

  &__main {
    padding: 0.8rem 0.7rem;
    border-radius: 10px;
    font-family: inherit;
    outline: none;
    font-size: 0.7rem;
    font-weight: 500;
    border: none;
    border: 1px solid #9d9d9d;
    color: #727272;
    background: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: auto;
    margin-right: 105px;
    cursor: pointer;
    position: relative;
    -webkit-tap-highlight-color: transparent;

    button {
      margin: 0;
      font-weight: 500;
      font-size: 0.7rem;
      padding: 0.2rem 0.5rem;
      cursor: pointer !important;
      border-radius: 6px;
      width:fit-content;
      display: block;
    }

    span {
      margin-left: 0.3rem;
      cursor: pointer;
    }
  }

  &__options {
    position: absolute;
    right: 105px;
    top: 45px;
    width: 100%;
    width: auto;
    height: auto;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 1rem;
    padding-bottom: 1rem;
    background: #ffffff;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.13);
    border-radius: 16px;

    animation: showDatePicker 0.2s ease-in-out 1;

    @keyframes showDatePicker {
      from {
        opacity: 0;
        transform: translateY(30px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    button {
      margin: 0;
      font-weight: 500;
      font-size: 0.78rem;
      padding: 0.2rem 0.5rem;
      cursor: pointer !important;
      border-radius: 6px;
      width: 105px;
      display: block;
      margin-top: 0.7rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
