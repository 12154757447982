.verify {
    padding: 1rem;
    font-family: inherit;
    padding-bottom: 3rem;
    position: relative;
  
    &__heading {
    //   display: grid;
    //   grid-template-columns: 100px 1fr;
    //   align-items: center;
      margin-bottom: 3rem;
      // background: red;
      h3 {
        font-size: 1.1rem;
        font-family: inherit;
        font-weight: 500;
        margin: 0;
        margin-top: .1rem;
        text-align: center;
      }
    }
    &__icon {
      cursor: pointer;
      width: fit-content;
      transform: translateX(-4px);
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  
  
    &__amount {
      margin-top: 3rem;
      margin-bottom: 2rem;
      &__naira {
        text-align: center;
        display: flex;
        align-items: center;
        font-weight: 800;
        color: #5c5c5c;
        // background: red;
        width: fit-content;
        margin: 0;
        margin: 0 auto;
        font-size: 2.5rem;
      }
      img {
        width: 100%;
        display: block;
        max-width: 250px;
        margin: 0 auto;
      }
  
      &__crypto {
        text-align: center;
        font-size: 0.8rem;
        font-weight: 500;
        color: #696969;
        margin: 0;
      }
    }
    &__proceed {
      margin-top: 2rem;
    }
  
    &__network {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;
      border-radius: 8px;
      background: #f5f5f5;
      height: 50px;
      padding: 0 1rem;
      margin-top: 1rem;
      img {
        width: 20px;
      }
      p {
        font-weight: 500;
        color: #8d8d8d;
        font-size: 0.9rem;
      }
    }
  
    &__networks {
      margin-top: 4rem;
    }
  
    &__warning {
      display: flex;
      align-items: center;
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
      p {
        color: #e16c00;
        font-weight: 400;
        font-size: 0.8rem;
        margin-left: 0.5rem;
      }
    }
  
    &__verify{
      display: flex;
      justify-content: center;
      flex-direction: column;  
      width: fit-content;
      margin: auto;
      
      h4{
        text-align: center;
        margin-top: 1rem;
        font-weight: 600;
        font-size: .9rem;
        color: #636363;
      }
  
      &__box{
        width: 100%;
        height: calc(100vh - 250px);
        display: grid;
        place-content: center;
      }
    }
    &__success{
      margin: auto;
      width: fit-content;
      margin-top: 6rem;
      margin-bottom: -2rem;
      &__message{
        text-align: center;
        font-weight: 600;
        font-size: .9rem;
        color: #636363;
        margin-bottom: 8rem;
      }
    }
  }
  