.transactions {
  padding: 1rem;
  font-family: inherit;
  padding-bottom: 3rem;
  position: relative;

  &__heading {
    // display: grid;
    // grid-template-columns: 100px 1fr;
    // align-items: center;
    margin-bottom: 2rem;
    // background: red;
    h3 {
      font-size: 1.1rem;
      font-family: inherit;
      font-weight: 500;
      margin: 0;
      text-align: center;
      margin-top: .1rem;
    }
  }
  &__icon {
    cursor: pointer;
    width: fit-content;
    transform: translateX(-4px);
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__actions{
    //display: grid;
    align-items: center;
    justify-content: space-between;
  }
  &__filter{
    position: relative;
  }


  &__datePicker {
    padding: 0.8rem 0.7rem;
    border-radius: 10px;
    font-family: inherit;
    outline: none;
    font-size: 0.7rem;
    font-weight: 500;
    border: none;
    border: 1px solid #9d9d9d;
    color: #727272;
    background: #fff;
    text-decoration: none;
    display: block;
    margin-left: auto;
    width: 90px;
   

    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &__container {
      position: relative;
      margin-top: -40px;
      width: fit-content;
      margin-left: auto;
    }
    &__menu {
      position: absolute;
      // background: red;
      top: 2.9rem;
      right: 0rem;
      width: 100%;
      max-width: 300px;
      animation: showDatePicker 0.2s ease-in-out 1;
      z-index: 2;

      @keyframes showDatePicker {
        from {
          opacity: 0;
          transform: translateY(30px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__group {
    margin-top: 3rem;
    &:nth-child(1) {
      margin-top: 1rem;
    }

    &__heading {
      font-size: 1.1rem;
      font-weight: 500;
      color: #616161;
      margin: 0;
      margin-bottom: 0.3rem;
    }
  }
  &__loading , &__empty {
    color: #777777;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    line-height: 1.3;
    padding-top: 80px;
    max-width: 200px;
    margin: auto;
  }

  &__empty{
    font-weight: 400;
  }
}
