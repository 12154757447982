.home {
  background: #16161b;
  color: #fff;
  &__main {
    display: grid;
    grid-template-columns: 1fr 450px;
    height: fit-content;
    padding: 0 6rem;
    @media (max-width: 1024px) {
      padding: 0 2rem;
    }
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 600px) {
      padding: 0 1.5rem;
    }
    @media (max-width: 355px) {
        padding: 0 1rem;
      }

    &__text {
      color: #fff;
      // background: pink;

      &__heading {
        color: #fff;
        font-size: 3.6rem;
        font-weight: 800;
        margin: 0;
        margin-top: 12rem;
        max-width: 570px;
        @media (max-width: 900px) {
          margin-top: 7rem;
        }
        @media (max-width: 600px) {
          font-size: 2.5rem;
          margin-top: 3rem;
        }
        @media (max-width: 480px) {
          font-size: 2rem;

          font-weight: 800;
          max-width: 350px;
          text-align: center;
          margin: auto;
          margin-top: 4rem;
        }
        @media (max-width: 355px) {
          font-size: 1.9rem;
        }
      }
      &__desc {
        color: #acacac;
        font-size: 1.5rem;
        margin-top: 1rem;
        font-weight: 300;
        max-width: 570px;
        @media (max-width: 600px) {
          font-size: 1.2rem;
        }
        @media (max-width: 480px) {
          font-size: 1rem;
          max-width: 300px;
          text-align: center;
          margin: auto;
          margin-top: 0.5rem;
        }
      }
      &__button {
        background: #4438cb;
        border-radius: 10px;
        border: none;
        outline: none;
        color: #fff;
        font-family: inherit;
        padding: 0.8rem 2.5rem;
        font-size: 1.3rem;
        margin-top: 1rem;
        cursor: pointer;
        font-weight: 500;
        @media (max-width: 600px) {
          font-size: 1rem;
          text-align: center;
        }
        @media (max-width: 480px) {
          display: block;
          margin: auto;
          margin-top: 1rem;
        }
      }
    }

    &__images {
      width: 100%;
      position: relative;
      min-height: 730px;
      @media (max-width: 900px) {
        margin-top: 4rem;
      }
      @media (max-width: 600px) {
        margin-top: 6rem;
        min-height: calc(200px + 100vw);
        padding-bottom: 2rem;

        // width: 70vw;
      }
      @media (max-width: 480px) {
        transform: translateX(-10vw);
      }
    }

    &__image {
      position: absolute;
      width: 280px;
      @media (max-width: 600px) {
        width: 60%;
      }

      &__one {
        top: 0rem;
        right: 8rem;
        z-index: 1;
        @media (max-width: 600px) {
          right: 20%;
        }
      }
      &__two {
        right: 0rem;
        top: 7rem;
      }
    }
  }
}
