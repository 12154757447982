.transaction{


    display: grid;
    grid-template-columns: 50px 1fr 100px;
    align-items: center;
    grid-gap: 1rem;

    padding-bottom: .7rem;
    padding-top: .7rem;
    border-bottom: 1px solid #EBEBEB;
    cursor: pointer;
    


    &__icon{
       
        
        transform: rotate(180deg);
        margin-bottom: .4rem;

        &__box{
            background: #DFDFDF;
            border-radius: 10px;
            display: grid;
            place-content: center;
            width: 50px;
            height: 50px;
        }
    }

    &__desc{

        h4{
            margin: 0;
            font-weight: 500;
            // text-transform: lowercase;
            font-size: .78rem;
           // background: #E10000;
            //background:  #4438CB;
            //background: #39A648;
            padding:  .2rem .5rem;
            border-radius: 6px;
            // color: #fff;
            //color: #D9D9D9;
            //color: #fff;
            width: fit-content;
        }
        p{
            font-size: .75rem;
            margin: 0;
            margin-top: .2rem;
            color: #838383;
        }
    }
    &__amount{

        font-size: .9rem;
        display: flex;
        align-items: center;
        margin-left: auto;

        span{
            display: block;
           // background: red;
            // &:nth-child(2){
            //     margin-top: .2rem;
            //     width: 13px;
            // }
            &:nth-child(1){
                margin-top: -.1rem;
            }
        }
    }
}