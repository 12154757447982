.makePayment {
  padding: 1rem;
  position: relative;
  font-family: inherit;
  padding-bottom: 3rem;

  &__heading {
    margin-bottom: 3rem;
    h3 {
      font-size: 1.1rem;
      font-family: inherit;
      font-weight: 500;
      margin: 0;
      margin-top: 0.1rem;
      text-align: center;
    }
  }
  &__icon {
    cursor: pointer;
    width: fit-content;
    transform: translateX(-4px);
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__amount {
    margin-top: 3rem;
    margin-bottom: 2rem;
    &__naira {
      text-align: center;
      display: flex;
      align-items: center;
      font-weight: 800;
      color: #5c5c5c;
      // background: red;
      width: fit-content;
      margin: 0;
      margin: 0 auto;
      font-size: 2.5rem;
    }
    img {
      width: 100%;
      display: block;
      max-width: 250px;
      margin: 0 auto;
    }

    &__crypto {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 500;
      color: #696969;
      margin: 0;
    }
  }
  &__proceed {
    margin-top: 2rem;
  }

  &__network {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    border-radius: 8px;
    background: #f5f5f5;
    height: 50px;
    padding: 0 1rem;
    margin-top: 1rem;
    img {
      width: 20px;
    }
    p {
      font-weight: 500;
      color: #8d8d8d;
      font-size: 0.9rem;
    }
  }

  &__networks {
    margin-top: 4rem;
  }

  &__warning {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;
    margin-top: 1rem;
    p {
      color: #e16c00;
      font-weight: 400;
      font-size: 0.8rem;
      margin-left: 0.5rem;
    }
  }

  &__verify {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    margin: auto;

    h4 {
      text-align: center;
      margin-top: 1rem;
      font-weight: 600;
      font-size: 0.9rem;
      color: #636363;
    }

    &__box {
      width: 100%;
      height: calc(100vh - 250px);
      display: grid;
      place-content: center;
    }
  }
  &__success {
    margin: auto;
    width: fit-content;
    margin-top: 6rem;
    margin-bottom: -2rem;
    &__message {
      text-align: center;
      font-weight: 600;
      font-size: 0.9rem;
      color: #636363;
      margin-bottom: 8rem;
    }
  }

  &__remotePayment {
    margin-top: 1rem;
    display: flex;
    align-items: center;

    &__toggle {
      width: 35px;
      height: 16px;
      border: 2px solid #8b8b8b;
      border-radius: 20px;
      position: relative;
      cursor: pointer;
      &__active {
        border: 2px solid #4438cb;
      }
      &__inActive {
        border: 2px solid #8b8b8b;
      }

      &__ball {
        width: 10px;
        height: 10px;
        background: #8b8b8b;
        border-radius: 50%;
        position: absolute;
        top: calc(10px - 9px);
        left: 2px;
        transition: left 0.1s linear;

        &__active {
          left: 19px;
          transition: left 0.1s linear;
          background: #4438cb;
        }
        &__inActive {
          left: 2px;
          right: unset;
          transition: left 0.1s linear;
          background: #8b8b8b;
        }
      }
    }
    p {
      color: #616161;
      font-size: 0.87rem;
      font-weight: 500;
      margin-left: 0.5rem;
    }
  }

  &__toggleNew {
    width: 100%;
    max-width: 300px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 40px;
    margin-bottom: 2rem;
    border: 5px solid #dedede;
    @media (max-width: 370px) {
      max-width: 260px;
    }

    &__button {
      width: 100%;
      height: 100%;
      font-family: inherit;
      background: #fff;
      background: #dedede;
      cursor: pointer;
      &__active {
        background: #4438cb;
        color: #fff;
      }
    }
  }
}

.link {
  padding: 1rem;

  &__heading {
    font-size: 1rem;
    font-weight: 500;
    color: #262626;
    margin-top: 1rem;
  }

  &__box {
    width: 100%;
    height: 45px;
    display: grid;
    grid-template-columns: 1fr 60px;
    background: #eaeaea;
    padding: 0.2rem;
    border-radius: 5px;
    margin: 2rem 0;
    margin-bottom: 4rem;
  }

  &__item {
    // background: gray;
    // border-top-left-radius: 8px;
    // border-bottom-left-radius: 8px;
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    p {
      width: calc(100% - 0rem);
      font-size: 0.9rem;
      overflow: hidden;
    }
    span {
      display: -webkit-box !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis !important;
    }
  }
  &__button {
    border-radius: 5px;
    background: #4438cb;
    color: #fff;
    font-family: inherit;
    cursor: pointer;
  }
}
