@import "../../../styles/variables";

.home {
  padding: 1rem;
  padding-bottom: 3rem;

  &__heading {
    display: grid;
    grid-template-columns: 1fr 150px;
    align-items: center;
    h2 {
      font-size: 1.2rem;
      font-weight: 600;
    }

    button {
      border: 1px solid #e16c00;
      font-size: .8rem;
      width: fit-content;
      margin-left: auto;
      padding: .5rem .7rem;
      font-family: inherit;
      color: #e16c00;
      border-radius: 12px;
      background: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      span{
        margin-left: .1rem;
      }
    }
  }

  &__balance {
    background: $col-primary;
    width: 100%;
    height: 150px;
    border-radius: 20px;
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-image: url(../../../assets/images/balance-bg-main.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;

    &__desc {
      color: #cfcfcf;
      font-weight: 400;
      font-size: 1rem;
      margin: 0;
    }

    &__amount {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      color: #fff;
      font-weight: 900;
      font-size: 3rem;

      span {
        display: block;
        // background: red;
        height: fit-content;
        margin: 0;
        padding: 0;
      }

      b {
        font-size: 1rem;
        color: #cfcfcf;
      }
    }
  }

  &__transactions {
    margin-top: 3rem;
    &__heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      h4 {
        font-size: 1rem;
        font-weight: 600;
      }

      button {
        color: #656565;
        font-family: inherit;
        font-size: 0.9rem;
        outline: none;
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }

    &__items {
      min-height: 200px;

      &__empty {
        color: #777777;
        font-size: 0.9rem;
        text-align: center;
        max-width: 320px;
        margin: auto;
        line-height: 1.3;
        padding-top: 80px;
      }
    }
  }

  &__makePayment {
    margin-top: 4rem;
    cursor: pointer;
   

    &__box{
      position: sticky;
      bottom: 1rem;
      width: inherit;
    }
  }
}
