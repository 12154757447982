.transactionDetails {
  padding-top: 1rem;
  position: relative;
  font-family: inherit;
  padding-bottom: 3rem;

  &__heading {
    margin-bottom: 3rem;
    h3 {
      font-size: 1.1rem;
      font-family: inherit;
      font-weight: 500;
      margin: 0;
      margin-top: 0.1rem;
      text-align: center;
    }
  }
  &__icon {
    cursor: pointer;
    width: fit-content;
    transform: translateX(-4px);
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__amount {
    margin-top: 3rem;
    margin-bottom: 1rem;

    &__naira {
      text-align: center;
      display: flex;
      align-items: center;
      font-weight: 800;
      color: #5c5c5c;
      // background: red;
      width: fit-content;
      margin: 0;
      margin: 0 auto;
      font-size: 2.5rem;
      padding-right: 1rem;
    }
    img {
      width: 100%;
      display: block;
      max-width: 250px;
      margin: 0 auto;
    }

    &__crypto,
    &__heading {
      text-align: center;
      font-size: 0.9rem;
      font-weight: 500;
      color: #696969;
      margin: 0;
    }
    &__heading {
      margin-bottom: 0.5rem;
    }
    &__crypto {
      margin: 0;
    }
  }
  &__proceed {
    margin-top: 4rem;
    padding: 0 1rem;
  }

  &__status {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    padding-right: .4rem;

    h4 {
      margin-left: 0.1rem;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  &__details {
    border-top: 1px solid #d9d9d9;
    position: relative;
    padding: 0 1rem;
    margin-top: 4rem;
    padding-top: 2rem;
  }
  &__detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    h4 {
      color: #696969;
      font-weight: 400;
      font-size: 0.9rem;
    }
    p {
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: calc(100% - 130px);
      // background: red;
      font-size: 0.9rem;

      span {
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis !important;
      }
      b{
        font-weight: 400;
      }
      img{
        width: 20px;
        margin-right: .2rem;
      }
    }

    &__copy {
      width: 25px;
      cursor: pointer;
    }
  }
}
