.day{
    font-size: .9rem;
    outline: none;
    border: none;
    background: transparent;
    // background: red;
    width: 100%;
    padding: .7rem 0;
    border-radius: 10px;
    cursor: pointer;
}