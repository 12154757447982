.email {
  padding: 2rem 4rem;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-top: 0rem;
  }

  &__icon {
    width: 40%;
    display: block;
    margin: auto;
    @media (max-width: 500px) {
      width: 50%;
    }
  }

  &__form {
    margin: 3rem 0;
    @media (max-width: 500px) {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    &__input {
      width: 100%;
      border: none;
      padding: 0;
      height: 55px;
      outline: none;
      border: 1px solid #d1d1d1;
      border-radius: 8px;
      font-size: 1.1rem;
      padding: 0 1rem;
      background: #f4f4f4;
      border: 1px solid #d1d1d1;
      @media (max-width: 500px) {
        font-size: 1rem;
        height: 50px;
      }
    }
    &__submit {
      font-family: inherit;
      display: block;
      margin-top: 2rem;
      width: 100%;
      height: 55px;
      background: #4438cb;
      border-radius: 10px;
      color: #fff;
      font-weight: 600;
      font-size: 1.1rem;
      @media (max-width: 500px) {
        margin-top: 1rem;
        font-weight: 500;
        font-size: 1rem;
        height: 50px;
      }
    }
  }

  &__submitted {
    padding: 2rem 4rem;
    @media (max-width: 500px) {
      padding: 2rem 1rem;
      padding-top: 3rem;
    }
    img {
      width: 40%;
      margin: auto;
      display: block;
      @media (max-width: 500px) {
        width: 50%;
      }
    }

    h3 {
      text-align: center;
      color: #acacac;
      font-size: 1.1rem;
      margin-top: 5rem;
      margin-bottom: 2rem;
      @media (max-width: 500px) {
        margin-top: 3rem;
        font-size: 1rem;
      }
    }
  }
}
