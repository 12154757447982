.pay {
  padding: 1rem;
  font-family: inherit;
  position: relative;
  padding-bottom: 3rem;

  &__heading {
    //   display: grid;
    //   grid-template-columns: 100px 1fr;
    //   align-items: center;
    margin-bottom: 3rem;
    // background: red;
    h3 {
      font-size: 1.1rem;
      font-family: inherit;
      font-weight: 500;
      margin: 0;
      margin-top: 0.1rem;
      text-align: center;
    }
  }
  &__icon {
    cursor: pointer;
    width: fit-content;
    transform: translateX(-4px);
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__amount {
    margin-top: 3rem;
    margin-bottom: 2rem;

    &__rate{
      font-size: 1rem;
      font-weight: 500;
      color: #696969;
      text-align: center;
      margin: 0.5rem 0;
      margin-top: .3rem;
    }
    &__naira {
      text-align: center;
      display: flex;
      align-items: center;
      font-weight: 800;
      color: #5c5c5c;
      // background: red;
      width: fit-content;
      margin: 0;
      margin: 0 auto;
      font-size: 2.5rem;
    }
    // img {
    //   width: 100%;
    //   display: block;
    //   max-width: 250px;
    //   margin: 0 auto;
    // }

    &__crypto {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 500;
      color: #696969;
      margin: 0 auto;
      display: flex;
      align-items: center;
      width: fit-content;
      
      

      &__img{
        width: 40px;
        margin: 0 auto;
        display: block;
        margin-bottom: 7px;
      }
    //   font-weight: 800;
    //   color: #5c5c5c;
    //   font-size: 2.5rem;
    //   max-width: calc(100% - 100px);
    //   background: red;
    //   display: inline-block;
    //   white-space: wrap;
    //   word-wrap: break-word;
    //   margin: 0 auto;
      
      img {
        width: 30px;
        margin-left: 0.1rem;
        display: inline;
      }
    }
  }
  &__proceed {
    margin-top: 2rem;
  }

  &__network {
    display: grid;
    grid-template-columns: 30px 1fr;
    align-items: center;
    border-radius: 8px;
    background: #f5f5f5;
    height: 50px;
    padding: 0 1rem;
    margin-top: 1rem;
    img {
      width: 20px;
    }
    p {
      font-weight: 500;
      color: #8d8d8d;
      font-size: 0.9rem;
    }
  }

  &__networks {
    margin-top: 4rem;
  }

  &__warning {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: auto;
    margin-top: 1rem;
    p {
      color: #e16c00;
      font-weight: 400;
      font-size: 0.8rem;
      margin-left: 0.5rem;
    }
  }

  &__verify {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    margin: auto;

    h4 {
      text-align: center;
      margin-top: 1rem;
      font-weight: 600;
      font-size: 0.9rem;
      color: #636363;
    }

    &__box {
      width: 100%;
      height: calc(100vh - 250px);
      display: grid;
      place-content: center;
    }
  }
  &__success {
    margin: auto;
    width: fit-content;
    margin-top: 6rem;
    margin-bottom: -2rem;
    &__message {
      text-align: center;
      font-weight: 600;
      font-size: 0.9rem;
      color: #636363;
      margin-bottom: 8rem;
    }
  }

  &__qrCode {
    // background: red;
    margin: auto;
    width: fit-content;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
  }

  &__link {
    
    max-width: 220px;
    margin: auto;
    margin-bottom: 1rem;
    
    height: 30px;
    cursor: pointer;
    display: grid;
    // grid-template-columns: 1fr 25px;
    align-items: center;
    background: #f5f5f5;
    border-radius: 5px;
    

   
    span {
      font-size: 0.9rem;
      display: inline-block;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      background: #f5f5f5;
      padding: 0 0.4rem;
      border-radius: 5px;
      height: 30px;
      display: grid;
      align-items: center;
    }
  }
}
