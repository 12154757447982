.keyboard{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: .5rem;

    &__button{

        // background: blue;
        width: 100%;
        height: 70px;
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 500;
        background: transparent;
        border: none;
        outline: none;
        color: #000;
        cursor: pointer;
    }
}