.verifyPhone {
  padding: 1rem;
  font-family: inherit;
  padding-bottom: 3rem;
  position: relative;

  &__heading {
    // display: grid;
    // grid-template-columns: 100px 1fr;
    // align-items: center;
    margin-bottom: 3rem;
    // background: red;
    h3 {
      font-size: 1.1rem;
      font-family: inherit;
      font-weight: 500;
      margin: 0;
      margin-top: .1rem;
      text-align: center;
    }
  }
  &__icon {
    cursor: pointer;
    width: fit-content;
    transform: translateX(-4px);
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  &__login {
    margin-top: 2rem;
  }
  &__inputPin {
    margin-top: 2rem;
  }
  &__keyboard{
    margin-top: 2rem;
  }

  &__desc , &__resend , &__call {
    color: #777777;
    font-size: .9rem;
    text-align: center;
    max-width: 300px;
    margin: auto;
    line-height: 1.5;
    b{
      color: #000;
      cursor: pointer;
    }
  }

  &__resend{
     margin-top: 3rem;
  }
  &__call{
    margin-top: 1rem;
  
  }
}
