.month {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  background: red;
  box-sizing: border-box;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background: #ffffff;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.13);
  border-radius: 16px;

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__arrow {
      cursor: pointer;
    }

    &__label {
      display: flex;
      align-items: center;
      h4 {
        font-family: inherit;
        color: #0e0e0f;
        font-size: 1rem;
        font-weight: 500;
        margin-right: 0.2rem;
      }

      &__arrow {
      }
    }
  }

  &__days {
    // display: flex;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    // background: red;
    
    margin-bottom: 0rem;
    height: 50px;
    
    h5 {
      color: #797b86;
      font-family: inherit;
      font-weight: 500;
      margin: 0;
      text-align: center;
    }
  }

  &__day__block {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: .1rem;
  }
}
