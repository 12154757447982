@import "../../styles/variables";

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: red;
  z-index: 3;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.9px);
  -webkit-backdrop-filter: blur(12.9px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: grid;
  place-content: center;

  &__main{
    display: flex;
    align-items: center;
    h4{
        font-size: 1rem;
        font-weight: 500;
        color: $col-primary;
    }
  }
}
